import { Button, CircularProgress } from '@mui/material';
import React from 'react';

const SubmitButton = ({ type, children, isLoading, bgcolor = '#0029A5' }) => {
  return (
    <Button
      type={type}
      variant="contained"
      sx={{
        width: '100%',
        borderRadius: '25px',
        padding: '12px 0px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '500',
        bgcolor: bgcolor,
      }}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={28} sx={{ color: '#595F6F' }} />
      ) : (
        children
      )}
    </Button>
  );
};

export default SubmitButton;
