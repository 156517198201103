import { useRouter } from 'next/navigation';
import useCheckMobileAgent from '@/hooks/useCheckMobileAgent';

interface DeviceRedirectOptions {
  mobileRoute?: string;
  desktopRoute?: string;
  callbackUrl?: string;
}

/**
 * 根據裝置類型進行路由重定向的 Hook
 * @param lang 當前語言
 * @param options 重定向選項
 * @returns 重定向函數
 */
export function useDeviceRedirect(lang: string) {
  const router = useRouter();
  const isMobile = useCheckMobileAgent();

  const redirectBasedOnDevice = (options: DeviceRedirectOptions = {}) => {
    const {
      mobileRoute = 'admin',
      desktopRoute = 'app',
      callbackUrl = '',
    } = options;

    if (isMobile) {
      // 手機裝置跳轉邏輯
      router.push(`/${lang}`);
    } else {
      // 桌機裝置跳轉邏輯
      if (callbackUrl) {
        router.push(callbackUrl);
      } else {
        router.push(`/${lang}/${desktopRoute}`);
      }
    }
  };

  return { redirectBasedOnDevice, isMobile };
}
