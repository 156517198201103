'use client';

import { useAppSelector } from '@/lib/hooks';

const LoginTip = ({t}) => {
  const { data } = useAppSelector((state) => state.user.login);
  const { errorMessage } = useAppSelector((state) => state.user);
  return (
    <div
      className={`${
        data?.isSucceed === false ? 'block' : 'hidden'
      } mx-auto w-full rounded-lg bg-complementary-orange-900/20 p-4`}
    >
      <p className="text-center font-medium">
        {t.loginSubmitErrorText}
      </p>
      {errorMessage && (
        <p className="text-center font-medium">{errorMessage}</p>
      )}
    </div>
  );
};

export default LoginTip;
