'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import Image from 'next/image';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from '@/lib/hooks';
import { setStoreEmail } from '@/lib/users/userSlice';
import useCheckMobileAgent from '@/hooks/useCheckMobileAgent';

import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SubmitButton from '@/components/adminConsole/button/SubmitButton';
import Visibility from '@/assets/icons/adminConsole/visibility.svg?url';
import InVisibility from '@/assets/icons/adminConsole/invisibility.svg?url';
import { useTranslation } from '@/app/i18n/client';
import BaseInputTextField from '@/components/base/BaseInput/BaseInputTextField';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLoginMutation } from '@/lib/users/usersAPI';
import { useDeviceRedirect } from '@/hooks/useDeviceRedirect';
import { setToken } from '@/utils/setToken';

const LoginForm = ({ lang }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const searchParams = useSearchParams();
  const email = decodeURIComponent(searchParams.get('email') ?? '') || '';
  const rawCallbackUrl = searchParams.get('callbackUrl') ?? '';
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);

  const { t } = useTranslation(lang, 'common');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const schema = z.object({
    email: z.string().email({ message: t('adminConsole.EmailRequiredText') }),
    password: z.string().min(8, {
      message: t('adminConsole.loginPasswordRequiredText'),
    }),
  });

  const form = useForm({
    defaultValues: {
      email: email || '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const [login, { isLoading }] = useLoginMutation();
  const { redirectBasedOnDevice } = useDeviceRedirect(lang);

  const handleLoginSubmit = async (data) => {
    try {
      setIsLoginFailed(false);
      dispatch(setStoreEmail(data.email));
      const res = await login(data).unwrap();

      // 帳號密碼有誤
      if (!res.isSucceed && !res.isVerified) {
        setIsLoginFailed(true);
        return;
      }
      // 未完成註冊驗證
      if (res.isSucceed && !res.isVerified) {
        router.push(`/${lang}/signup/verify`);
      }

      // 驗證成功，代表帳號密碼正確，且完成註冊驗證流程
      if (res.isSucceed && res.isVerified) {
        setToken(res.token, res.refreshToken);
        let callbackUrl;
        try {
          const url = new URL(rawCallbackUrl, window.location.origin);
          callbackUrl = url.pathname + url.search;
        } catch (error) {
          callbackUrl = '';
        }

        setTimeout(() => {
          redirectBasedOnDevice({
            desktopRoute: 'app',
            callbackUrl: callbackUrl === '/' ? '' : callbackUrl,
          });
        }, 100);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && typeof window !== 'undefined' && rawCallbackUrl) {
      const url = new URL(rawCallbackUrl, window.location.origin);
      const callbackUrl = url.pathname + url.search;
      router.push(callbackUrl);
    }
  }, [rawCallbackUrl, router]);

  return (
    <>
      {isLoginFailed && (
        <div className="typo-label-md rounded-lg bg-[#E89B09]/20 p-4 text-center text-gray-900">
          We didn’t recognize you. Please try again.
        </div>
      )}
      <form onSubmit={handleSubmit(handleLoginSubmit)} noValidate>
        <div className="flex flex-col gap-4">
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <BaseInputTextField
                label={t('adminConsole.loginEmailLabel')}
                type="email"
                variant="filled"
                {...field}
                value={field.value || ''}
                slotProps={{
                  input: { disableUnderline: true },
                  htmlInput: { inputMode: 'email', autoComplete: 'username' },
                }}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <BaseInputTextField
                label={t('adminConsole.loginPasswordLabel')}
                type={showPassword ? 'text' : 'password'}
                variant="filled"
                autoComplete="current-password"
                {...field}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? (
                            <Image src={Visibility} alt="hidden password" />
                          ) : (
                            <Image src={InVisibility} alt="show password" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
          />
          <Link
            href={`/${lang}/forget`}
            className="w-fit cursor-pointer text-[14px] font-medium leading-[16px] text-primary-900"
          >
            {t('adminConsole.loginForgotText')}
          </Link>
          <SubmitButton type="submit" isLoading={isLoading}>
            {t('adminConsole.loginText')}
          </SubmitButton>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
