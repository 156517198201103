'use client';

import { useParams, useRouter } from 'next/navigation';
import Google from '@/assets/icons/login&signup/google-icon.svg?url';
import Apple from '@/assets/icons/login&signup/apple-icon.svg?url';
import Facebook from '@/assets/icons/login&signup/facebook-icon.svg?url';

import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { useAppDispatch } from '@/lib/hooks';
import { useThirdLoginMutation } from '@/lib/users/usersAPI';
import { setFirebaseError } from '@/lib/users/userSlice';
import auth from '../../../firebase';
import Register from './Register';
import { setToken } from '@/utils/setToken';
import { useDeviceRedirect } from '@/hooks/useDeviceRedirect';

const registerList = [
  {
    name: 'Google',
    icon: Google,
    text: 'Google work account',
  },
];

function SSO({ t }) {
  const params = useParams();
  const { lang } = params;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { redirectBasedOnDevice } = useDeviceRedirect(lang);

  const [thirdLogin] = useThirdLoginMutation();

  const handleThirdProvider = async (chose) => {
    let result;

    try {
      dispatch(setFirebaseError('')); // clear firebaseError message
      switch (chose) {
        case 'Google': {
          const provider = new GoogleAuthProvider();
          result = await signInWithPopup(auth, provider);
          break;
        }
        case 'Facebook': {
          const provider = new FacebookAuthProvider();
          result = await signInWithPopup(auth, provider);
          break;
        }
        case 'Apple': {
          const provider = new OAuthProvider('apple.com');
          result = await signInWithPopup(auth, provider);
          break;
        }
        default:
          break;
      }

      const thirdResult = await thirdLogin({
        accessToken: result.user.accessToken,
      });

      const { isNewRegister, token, refreshToken, isSucceed, isVerified } =
        thirdResult.data;

      if (token && refreshToken) {
        setToken(token, refreshToken);
      }
      if (isNewRegister) {
        router.push(`/${lang}/signup/profile`);
      } else {
        if (isSucceed && isVerified) {
          redirectBasedOnDevice({
            desktopRoute: 'app',
          });
        } else {
          router.push(`/${lang}/signup/verify`);
        }
      }
    } catch (error) {
      const errorCode = error.code;
      if (errorCode === 'auth/account-exists-with-different-credential') {
        dispatch(
          setFirebaseError(
            'You have already registered with a different auth provider for that email.',
          ),
        );
      } else {
        dispatch(setFirebaseError('An error occurred. Please try again.'));
      }
    }
  };

  return (
    <div className="flex w-full flex-col gap-4 pt-4 md:max-w-[448px]">
      <div className="relative flex items-center justify-center gap-3">
        <span className="block h-[1px] w-full bg-gray-500" />
        <p className="flex-shrink-0 text-center text-sm text-gray-700">
          {t.loginContinueText}
        </p>
        <span className="block h-[1px] w-full bg-gray-500" />
      </div>

      <Register
        handleThirdProvider={handleThirdProvider}
        register={registerList}
      />
    </div>
  );
}

export default SSO;
