import Image from 'next/image';

function Register({ register, handleThirdProvider }) {
  return (
    <div className="flex w-full items-center justify-center gap-4">
      {register.map((item) => (
        <button
          onClick={() => {
            handleThirdProvider(item.name);
          }}
          type="button"
          key={item.name}
          className="flex w-full items-center justify-center gap-2 rounded-2xl bg-white py-2 shadow-sm transition-all duration-200 ease-out hover:bg-black/5"
        >
          <div className="h-9 w-9 p-[3px]">
            <Image
              src={item.icon}
              alt={`${item.name} sign up icon`}
              className="w-full"
            />
          </div>
          <p className="text-base text-gray-900">{item.text}</p>
        </button>
      ))}
    </div>
  );
}

export default Register;
