import React from 'react';
import {
  TextField,
  IconButton,
  InputAdornment,
  TextFieldProps,
  InputProps,
} from '@mui/material';
import Reset from '@/assets/icons/adminConsole/toast-reset.svg';

interface BaseInputTextFieldProps extends Omit<TextFieldProps, 'ref'> {
  label: string;
  value?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearFieldClick?: () => void;
  error?: any;
  helperText?: string;
  type?: InputProps['type'];
  autoFocus?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
  readOnly?: boolean;
  multiline?: boolean;
  maxRows?: number;
  fullWidth?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  size?: 'small' | 'medium';
}

const BaseInputTextField = React.forwardRef<
  HTMLInputElement,
  BaseInputTextFieldProps
>(
  (
    {
      label,
      value,
      onChange,
      onClearFieldClick,
      error,
      helperText,
      type,
      autoFocus,
      placeholder,
      icon,
      readOnly,
      multiline,
      maxRows,
      fullWidth = false,
      size = 'medium',
      ...props
    },
    ref,
  ) => {
    const showClearButton = onClearFieldClick && value;

    const handleNumberInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (type === 'number') {
        const allowedKeys = [
          'Backspace',
          'Delete',
          'ArrowLeft',
          'ArrowRight',
          'Tab',
          'Enter',
          '.',
        ];
        if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
          event.preventDefault();
        }
      }
    };

    return (
      <TextField
        inputRef={ref}
        label={label}
        variant="filled"
        type={type || 'text'}
        placeholder={placeholder || ''}
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        multiline={multiline}
        maxRows={maxRows}
        fullWidth={fullWidth}
        onKeyDown={type === 'number' ? handleNumberInputKeyDown : undefined}
        slotProps={{
          ...props.slotProps,
          input: {
            disableUnderline: true,
            readOnly,
            endAdornment: (
              <InputAdornment position="end">
                <div className="flex items-center gap-2">
                  {showClearButton && !readOnly && (
                    <IconButton
                      aria-label="toggle reset company name"
                      edge="end"
                      onClick={onClearFieldClick}
                    >
                      <Reset />
                    </IconButton>
                  )}
                  {icon}
                </div>
              </InputAdornment>
            ),
            inputMode: type === 'number' ? 'numeric' : 'text',
          },
        }}
        error={error}
        helperText={helperText || error?.message}
        size={size}
        sx={{
          '& .MuiInputBase-root': {},
          '& .MuiFilledInput-root': {
            backgroundColor: 'white',
            fontSize: '16px',
            border: readOnly ? 'none' : '1px solid #F0F0F2',
            borderRadius: '12px',
            pointerEvents: readOnly ? 'none' : 'auto',
            '&.Mui-focused': {
              backgroundColor: 'white',
              border: readOnly ? 'none' : '1px solid #3F8DFF',
            },
            '&.Mui-error': {
              borderColor: readOnly ? 'none' : '#E8095F80',
              borderWidth: readOnly ? '0px' : '1px',
            },
            '&:hover:not(.Mui-focused)': {
              borderColor: readOnly ? 'none' : '#BFC1C7',
              backgroundColor: readOnly ? 'white' : '#F0F0F2',
            },
          },
          ...props.sx,
        }}
        {...props}
      />
    );
  },
);

export default BaseInputTextField;
